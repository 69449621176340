.add-picture {
    width: 200px;
}

.add-picture p {
    font-size: 18px;
  font-weight: 500;
  margin-bottom: 15px;
  margin-top: 20px; 
}

.add-picture .img-box {
    width: 100%;
    height: 130px;
    border-radius: 10px;
    border: 2px solid var(--mid-grey);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background-size: cover;
}

.add-picture .img-box:not(.add) {
    border: none;
    margin-inline: 2px;
    margin-block: 2px;
    transition: box-shadow .2s;
}

.add-picture .img-box:not(.add) * {
    display: none;
}

.add-picture .img-box:not(.add):hover * {
    display: block;
    filter: invert(1);
}

.add-picture .img-box:not(.add):hover {
    
    box-shadow: inset 0 0 0 2000px rgba(0,0, 0, 0.4);
}

.add-picture .img-box svg {
    transition: transform .1s;
}

.add-picture .img-box:hover svg {
    transform: scale(1.1);
}

.add-picture .img-box a {
    margin-top: 15px;
}