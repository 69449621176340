.create-page h1 {
    margin-top: 20px;
    font-weight: 600;
    font-size: 24px;
    float: left;
    margin-left: 20px;
    width: 50%;
    margin-left: calc(25% - 100px - 20px);
    text-align: center;
    margin-top: 30px;
    line-height: 60px;
}

.create-page .content {
    width: 80%;
    padding-inline: 10%;
    float: left;
    height: calc(var(--box-height) - 210px);
    overflow-x: hidden;
    overflow-y: auto;
}

.create-page .button.cancel {
    margin-left: 20px;
    margin-top: 30px;
    float: left;
}

.create-page .button.finish {
    float: right;
    margin-top: 50px;
}

.create-page .button.finish.back {
    float: left;
}

.create-page .button.finish.back svg {
    order: -1;
    margin-left: 0;
    margin-right: 10px;
    transform: rotate(0);
    margin-left: 2px;
}

.create-page .button.finish.back:hover svg {
    margin-right: 12px;
    margin-left: 0;
}

.create-page .content .text-field, .create-page .content .multiple-select {
    margin-top: 20px;
    float: left;
    width: 100%;
}

.create-page .content .text-fields {
    width: calc(100% - 230px);
    float: left;
}

.create-page .content .add-picture {
    margin-left: 20px;
    float: left;
    margin-top : 20px;
}

.create-page .content .multiple-select .dropdown {
    width: 250px;
}

.create-page .content .multiple-select {
    height: 80px;
}

.create-page .content .choose-image p {
    font-size: 18px;
    margin-top: 20px;
    margin-bottom: 15px;
    font-weight: 500;
}

.create-page .content .choose-image {
    margin-top: 20px;
    margin-left: 40px;
    float: left;
    width: 180px;
}

.create-page .content .choose-image .add-img, .create-page .content .choose-image .edit-image {
    
    width: 100%;
    height: 130px;
}

.create-page .content .choose-image .add-img {
    border: 2px solid var(--mid-grey);
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.create-page .content .choose-image .add-img svg {
    margin-bottom: 15px;
    transition: transform .1s;
}

.create-page .content .choose-image .add-img:hover svg {
    transform: scale(1.1);
}

.create-page .input-flex {
    display: flex;
    gap: 30px;
    margin-bottom: 30px;
}

.create-page .input-flex .text-field {
    margin-top: 0;
}

.create-page .content .checkbox.standalone {
    margin-block: 20px;
}