.event-list-element {
    --height: 70px;
    width: calc(100% - 60px);
    padding-inline: 30px;
    padding-block: 5px;
    background-color: var(--light-grey);
    border-radius: 15px;
    height: var(--height);
    transition: margin .7s;
    margin-bottom: 20px;
    overflow: hidden;
}

.event-list-element .info {
    width: calc(100% + 400px);
    display: flex;
    align-items: center;
    float: left; 
    transition: margin .4s cubic-bezier(0.445, 0.050, 0.550, 0.950);
    
    transition-delay: .07s;
}

.event-list-element:not(.choose-list-element):hover .info {
    margin-left: -400px;
}

.event-list-element .edit-image {
    height: var(--height);
    margin-top: 0;
    border-radius: 100px;
    width: 150px;
}

.event-list-element p {
    width: 30%;
    text-align: center;
    font-size: 18px;
}

.event-list-element p.title p {
    opacity: .4;
    text-align: left;
    font-weight: 500;
}

.event-list-element p.title {
    width: calc(100% - 30% - 150px);
    text-align: left;
}

.event-list-element .action-buttons {
    width: 350px;
    height: 50px;
    margin-block: 10px;
}

.event-list-element .action-buttons .action {
    height: 50px;
    float: left;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.event-list-element .action-buttons .action svg {
    height: 30px;
    width: 30px;
    transform: scale(1.3);
}

.event-list-element .action-buttons .action svg > path {
    fill: black;
}

.event-list-element .action-buttons hr {
    height: 40px;
    margin-block: 5px;
    margin-inline: 10px;
    background-color: var(--light-grey);
    float: left;
}

.event-list-element .action-buttons .action {
    transition: margin .1s;
}

.event-list-element .action-buttons .action:hover {
    margin-top: 3px;
}

.event-list {
    height: calc(var(--box-height) - 170px);
    overflow-y: auto;
}

.event-list-element.choose-list-element {
    --height: 50px;
    cursor: pointer;
    transition: opacity .4s ease-in-out;
}

.event-list-element.choose-list-element.disabled {
    opacity: .6;
}

.event-list-element.choose-list-element .checkbox label {
    display: flex;
    justify-content: center;
    align-items: center;
}

.event-list-element.choose-list-element .edit-image {
    width: 90px;
    margin-left: 50px;
}