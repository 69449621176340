.save-button {
    position: absolute;
    right: calc(50% - 700px - 50px + 40px);
    background-color: var(--light-main);
    padding-inline: 40px;
    padding-block: 15px;
    border-radius: 40px;
    bottom: calc((100vh - var(--box-height)) / 2 + 40px);
    cursor: pointer;
    background-color: var(--light-grey);
}

.save-button svg {
    filter: invert(30%);
}

.save-button.active {
    background-color: var(--light-main);
}


.save-button.active svg {
    filter: none;
}

@media (max-width: 1530px) {
    .save-button {
        right: calc(50% - 500px - 50px + 40px)
    }
}