.tag-list input {
    border: none;
    color: var(--mid-grey);
    font-size: 17px;
    outline: none;
    line-height: 24px;
    width: 300px;
}

.tag-list {
    margin-top: 20px;
    float: left;
    width: 100%;
}

.tag-list p {
    font-size: 18px;
  font-weight: 500;
  margin-bottom: 15px;
  margin-top: 20px;
}

.tag-list .tags {
    display: flex;
    gap: 20px;
    width: 100%;
    flex-wrap: wrap;
}

.tag-list .tags .tag {
    padding: 3px;
    padding-inline: 8px;
    display: flex;
    border: 2px solid black;
    border-radius: 15px;
    align-items: center;
}

.tag-list .tags .tag svg {
    opacity: .7;
    height: 13px;
    margin-left: 5px;
    cursor: pointer;
}