.edit-image {
    width: 130px;
    height: 130px;
    border-radius: 15px;
    background-size: cover;
    background-position: center;
    cursor: pointer;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    margin-top: 16px;
}

.edit-image-box.nohover .edit-image {
    cursor: unset;
}

.edit-image * {
    opacity: 0;
    transition: opacity .2s;
}

.edit-image-box:not(.nohover) .edit-image:hover {
    box-shadow: inset 0 0 0 2000px rgba(0,0, 0, 0.4);
}

.edit-image-box:not(.nohover) .edit-image:hover * {
    opacity: 1;
}

.edit-image svg {
    width: 40px;
    height: 40px;
}

.edit-image p {
    color: var(--white);
    font-size: 17px;
    text-align: center;
    margin-top: 10px;
    width: 70%;
}

.edit-image-box .move {
    border-radius: 30px;
    border: 2px solid var(--black);
    width: 20px;
    padding-inline: 10px;
    padding-block: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    float: left;
    margin-top: 10px;
}

.edit-image-box .move.disabled {
    opacity: .2;
}

.edit-image-box .move.right {
    float: right;
}

.edit-image-box .move.right svg {
    transform: rotate(180deg);
}

.edit-image-box .move svg {
    filter: invert(1);
    width: 17px;
    height: 15px;
}