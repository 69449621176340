.sidebar .open {
    margin-top: 80px;
}

.sidebar .open p {
    font-size: 18px;
    font-weight: 500;
    display: inline-block;
    float: left;
}

.sidebar .open .switch {
    float: right;
}

.sidebar .open .title {
    width: 100%;
    height: 50px;
}

#club-page .sidebar .open .text-field {
    margin-top: 15px;
}

.sidebar .weekdays {
    width: calc(100% - 12px);
    float: both;
}

.sidebar .weekdays h3 {
    font-weight: 500;
    font-size: 16px;
    float: left;
    width: 125px;
    margin-block: 10px;
}

.sidebar .weekdays input {
    font-size: 16px;
    border: none;
    border-bottom: 2px solid black;
    float: left;
    margin-block: 10px;
    outline: none;
    width: calc(100% - 130px);

}