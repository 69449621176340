*, html, body {
    margin: 0;
    padding: 0;
    font-family: "Schibsted", sans-serif;
    color: var(--black);
    scroll-behavior: smooth;
}

body {
    overflow-y: hidden;
}

h1, h2, h3, h4, h5, h6 {
    font-family: 'Filson Pro', sans-serif;
}

.unselectable {
    
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}