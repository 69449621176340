#newsletter {
    padding-top: 40px;
}

#newsletter .disable {
    display: flex;
    gap: 10px;
    align-items: center;
    margin-bottom: 15px;
}

#newsletter p.info {
    margin-block: 10px;
    opacity: .7;
}

#newsletter p.info span {
    font-weight: 600;
}

#newsletter .main-box .content {
    height: fit-content;
}

#newsletter .button {
    float: left;
    margin-top: 10px;
    margin-bottom: 25px;
    width: 100px;
    margin-right: calc(100% - 100px);
}