.image-box {
    width: 100%;
    height: 200px;
    overflow: hidden;
    border-radius: 100px;
    display: flex;
    background-size: cover;
    background-position: center;
}

.image-box img.blur {
    filter: blur(20px);
    min-width: 100%;
}