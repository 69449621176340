

#club-page .club-content .text-field {
    margin-top: 30px;
}

.club-content textarea {
    height: 300px;
}

.club-content .edit-img-row {
    width: 100% !important;
}