.action-page {
    position: absolute;
    z-index: 2;
    width: calc(100vw - 400px);
    left: 400px;
    top: 0;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.action-page .page-box {
    
    padding: 50px;
    background-color: var(--white);
    border-radius: 15px;
    width: 330px;
}

.action-page.big .page-box {
    width: 650px;
    max-height: 700px;
    overflow-y: auto;
}

.action-page .page-box .close {
    cursor: pointer;
    margin-left: calc(100% - 20px);
    transition: transform .2s;
}

.action-page .page-box .close:hover {
    transform: scale(0.9);
}

.action-page .page-box .close svg {
    width: 20px;
}

.action-page p {
    margin-block: 20px;
    font-size: 17px;
}

.action-page .text-field {
    margin-top: 40px;
}

.action-page .button {
    margin-top: 100px;
    width: 100%;
}

.action-page-overlay {
    position: absolute;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(20, 20, 20, .3);
}

.action-page .text-field.second {
    margin-top: 80px;
}

.action-page .switch {
    width: 100%;
    margin-block: 30px;
}

.action-page h2 {
    font-size: 20px;
    font-weight: 500;
}

.action-page h3 {
    font-size: 14px;
    font-weight: 300;
    color: var(--mid-light-grey);
}

.action-page .page-box .draginfo {
    display: none;
}

.action-page .page-box.drag.overlay{
    display: flex;
}

.action-page .page-box.overlay {
    display: none;
    background-color: rgba(0, 0, 0, .7);
    position: absolute;
    padding-block: 0;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 30px;
}

.action-page .edit-image-box {
    margin-bottom: 40px;
}

.action-page .page-box.overlay svg {
    height: 60px;
    width: 60px;
    filter: invert(1);
}

.action-page .page-box.overlay a {
    font-size: 17px;
    font-weight: 500;
    color: white;
}

.action-page .page-box.loading.overlay {
    display: flex;
}