.popup {
    position: fixed;
    padding: 10px;
    padding-inline: 25px;
    background-color: var(--white);
    box-shadow: 1px 1px 20px rgba(50, 50, 50, .6);
    border-radius: 10px;
    text-align: center;
    left: 50%;
    transform: translate(-50%, 0);
    top: -150px;
    transition: top .4s ease;
    z-index: 5;
}

.popup.open {
    
    top: 20px;
}

.popup h1 {
    font-size: 17px;
    font-weight: 400;
}