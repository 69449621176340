#newsletter .testmail {
    width: 100%;
    display: flex;
    align-items: end;

}

#newsletter .testmail .button {
    height: 40px;
}

#newsletter .text-field {
    width: 100%;
}

#newsletter .main-box {
    overflow-y: auto;
}

#newsletter .main-box .content {
    margin-top: 50px;
}