.club-list-box {
    height: 130px;
    background-color: var(--light-grey);
    padding: 10px;
    border-radius: 10px;
    cursor: pointer;
}

.club-list-box.hidden h1 {
    text-decoration: line-through;
}

.club-list-box h1 {
    font-size: 18px;
    font-weight: 600;
    float: left;
    width: calc(100% - 120px);
    height: 110px;
}

.club-list-box h2 {
    font-size: 18px;
    color: var(--mid-light-grey);
    font-weight: 600;
}

.club-list-box .edit-image-box {
    margin-left: 10px;
    height: 110px;
    width: 110px;
    float: left;
}

.club-list-box .edit-image-box .edit-image {
    margin-top: 0;
    width: 100%;
    height: 100%;
}