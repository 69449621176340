.header-page .header {
    width: 70%;
    margin-inline: 15%;
    display: flex;
    padding-top: 25px;
    height: 40px;
}

.header-page .header a {
    font-size: 22px;
    text-align: center;
    flex: 1 1 0px;
    font-weight: 400;
    cursor: pointer;
    padding-bottom: 4px;
}

.header-page .header a.active {
    font-weight: 600;
    border-bottom: 2px solid var(--light-main);
}

.header-page .content {
    width: 550px;
    margin-left: calc(50% - 275px);
    overflow-x: auto;
    height: calc(var(--box-height) - 65px - 20px);
    margin-top: 20px;
}

.header-page.fullsize .content {
    width: 100%;
    margin: unset;
}

.header-page .content .text-field:nth-child(1) {
    margin-top: 30px;
}

.header-page .content .text-field, .header-page .content .multiple-select {
    margin-top: 80px;
}

.header-page .content .text-field {
    width: calc(100% - 20px);
}

.header-page .content .text-field-div {
    margin-top: 80px;
}

.header-page .content .text-field-div .text-field {
    margin-top: 0;
}

.header-page .content .text-field p {
    margin-bottom: 10px;
}

.header-page .content .icons {
    margin-top: 50px;
}

.header-page .content .icons p {
    margin-top: 10px;
    margin-bottom: 30px;
}

.header-page .text-field input {
    color: var(--mid-grey);
}

.header-page p.section-name {
    font-size: 18px;
    font-weight: 500;
    margin-top: 30px;
}