.icon-button {
    border: 1px solid var(--black);
    width: fit-content;
    border-radius: 20px;
    cursor: pointer;
    width: 70px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: margin .1s, width .1s, height .1s;
}
.icon-button.disabled {
    opacity: .2;
}
.icon-button svg {
    width: 20px;
    height: 20px;
}

.icon-button:not(.nohover):hover {
    width: 67px;
    margin-inline: 1.5px;

}