.text-field {
    display: block;
}

.text-field.autogrow {
    display: grid;
    margin-top: 30px;
}

.text-field.autogrow::after {
    /* Note the weird space! Needed to preventy jumpy behavior */
    content: attr(data-replicated-value) " ";
  
    /* This is how textarea text behaves */
    white-space: pre-wrap;
  
    /* Hidden from view, clicks, and screen readers */
    visibility: hidden;
  }
  .text-field.autogrow > textarea {
    /* You could leave this, but after a user resizes, then it ruins the auto sizing */
    resize: none;
  
    /* Firefox shows scrollbar on growth, you can hide like this. */
    overflow: hidden;
    height:unset;
  }
  .text-field.autogrow > textarea,
  .text-field.autogrow::after {
    /* Identical styling required!! */
    border: 1px solid black;
    padding: 0.5rem;
    font: inherit;
  
    /* Place on top of each other */
    grid-area: 2 / 1 / 2 / 2;
  }

.text-field p {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 15px;
    margin-top: 20px;
}

.text-field p a {
    background-color: var(--light-grey);
    padding-inline: 20px;
    padding-block: 3px;
    border-radius: 30px;
    float: right;
    cursor: pointer;
    font-size: 16px;
}

.text-field input, .text-field textarea {
    border: none;
    border-bottom: 2px solid var(--mid-grey);
    width: calc(100% - 4px);
    outline: none;
    padding-bottom: 7px;
    font-size: 17px;
    float: left;
    padding-inline: 2px;
    transition: padding .1s;
    color: var(--mid-grey);
    font-weight: 400;
}

.text-field textarea {
    resize: none;
    border: 2px solid var(--mid-grey);
    border-radius: 8px;
    padding: 6px;
    width: calc(100% - 22px);
    height: 80px;
    margin-bottom: 40px;
}

.text-field input:focus {
    padding-left: 4px;
    padding-right: 0px;
}

.text-field .icon {
    float: left;
    margin-left: -25px;
    cursor: pointer;
}

.text-field .icon svg {
    width: 22px;
}

.text-field .checkbox {
    width: 30px;
    height: 16px;
    float: left;
    margin-top: 20px;
}

.text-field-div {
    display: flex;
    gap: 20px;
    width: calc(100% - 20px);
}

.text-field-div .text-field {
    width: calc(100% - 20px);
}