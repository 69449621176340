.create-content {
    width: 100%;
    display: flex;
    gap: 20px;
    flex-direction: column;
    height: calc(var(--box-height) - 220px);
    overflow: auto;
    margin-top: 10px;
}

.create-content .paragraph {
    background-color: var(--light-grey);
    width: calc(100%);
    border-radius: 10px;
}

.create-content .create-paragraph {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 20px;
    align-items: center;
    cursor: pointer;
    height: 50px;

}

.create-content .create-paragraph svg {
    width: 20px;
}

.create-article .content {
    overflow-y: hidden;
}