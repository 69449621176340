.button {
    padding-inline: 30px;
    padding-block: 12px;
    border: none;
    background-color: var(--black);
    color: white;
    font-size: 17px;
    border-radius: 100px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.button.medium {
    padding-inline: 0;
    padding-block: 0;
}

.button.medium svg {
    height: 50px;
}

.button svg {
    
    height: 18px;
    margin-right: 2px;
}

.button.multipleChildren svg {
    
    margin-left: 10px;
}

.button.invert svg {
    
    filter: invert(1);
}

.button.thin {
    height: 35px;
    padding-inline: 15px;
}

.button.thin.invert svg {
    filter: invert(1);
}

.button.hidden {
    opacity: 0;
    cursor: unset;
}

.button.disabled {
    opacity: .2;
}