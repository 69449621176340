.multiple-switch {
    width: 100%;
}

.multiple-switch p {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 10px;
    margin-top: 20px;
}

.multiple-switch .options {
    width: 100%;
    border-radius: 100px;
    height: 40px;
    background-color: var(--light-grey);
    display: flex;
    align-items: center;
    padding: 5px;
}

.multiple-switch .options a {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    height: 40px;
    gap: 5px;
}

.multiple-switch .options a.active {
    background-color: white;
    border-radius: 100px;
}