.account-box {
    background-color: var(--light-grey);
    height: 120px;
    padding: 10px;
    border-radius: 10px;
    cursor: pointer;
    word-wrap: anywhere;
}

.account-box .online-state {
    width: 20px;
    height: 20px;
    border-radius: 10px;
    background-color: var(--green);
    opacity: .5;
    float: right;
}

.account-box.disabled h1 {
    text-decoration: line-through;
}

.account-box h1 {
    font-size: 20px;
    height: 100px;
    font-weight: 500;
}

.account-box h2 {
    font-size: 20px;
    height: 20px;
    color: var(--mid-light-grey);
    font-weight: 500;
}