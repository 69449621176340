#account-info .content h1 {
    margin-top: 50px;
    font-size: 27px;
    font-weight: 500;
}

#account-info .content h1.deactivated {
    text-decoration: line-through;
}

#account-info .content h3 {
    font-weight: 500;
    font-size: 20px;
    color: var(--mid-light-grey);
    margin-bottom: 60px;
}

#account-info .bottom {
    margin-top: 100px;
}

#account-info .bottom .info {
    width: 60%;
    float: right;
    text-align: right;
}

#account-info .bottom .info .label {
    color: var(--mid-light-grey);
    margin-top: 30px;
}

#account-info .bottom .info p {
    font-size: 17px;
    margin-block: 5px;
    font-weight: 500;
}

#account-info .bottom .buttons {
    width: 40%;
    float: left;
    margin-top: -25px;
}

#account-info .bottom .buttons p {
    margin-top: 9px;
    margin-bottom: 25px;
}



#account-info .content .button svg {
    transform: rotate(180deg);
    height: 18px;
    margin-left: 10px;
    margin-right: 2px;
    transition: margin .2s ease;
}

#account-info .content .button:hover svg {
    margin-left: 12px;
    margin-right: 0;
}

#account-info .placeholder {
    height: 25px;
}