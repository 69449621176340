:root {
    --black: rgb(10, 10, 10);
    --mid-grey: #484848;
    --mid-light-grey: #AEAEAE;
    --light-grey: #e9e9e9;
    --very-light-grey: #eee;
    --main: #264018;
    --light-main: #8bafd1;
    --white: #fff;
    --green: #84d05b;
}