header {
    padding-inline: 50px;
    padding-block: 70px;
    width: 300px;
    float: left;
    height: calc(100vh - 140px);
    z-index: 10;
}

header .mobile-open-button {
    display: none;
    cursor: pointer;
    position: absolute;
    margin-top: -30px;
    right: 10px;
    transform: rotate(-90deg);
    width: 20px;
    height: 20px;
    align-items: center;
    justify-content: center;
    transition: all .3s;
}

header .mobile-open-button svg {
    width: 17px;
    height: 17px;
}

header.mobile-open .mobile-open-button {
    transform: rotate(90deg);
    right: 30px;
}

#dashboard.header-mobile header {
    position: absolute;
    background-color: var(--white);
    left: -350px;
    transition: left .4s ease;
}

#dashboard.header-mobile header .mobile-open-button {
    display: flex;
}

#dashboard.header-mobile header.mobile-open {
    left: 0;
}

#dashboard.header-mobile header ul {
    position: absolute;
    z-index: 1;
    width: calc(100% - 100px);
}

#dashboard.header-mobile header .active-highlight {
    z-index: unset;
    width: 300px;
}

header h1 {
    font-weight: 400;
    font-size: 27px;
}


header h2 {
    font-weight: 1000;
    font-size: 27px;
}

header .active-highlight {
    background-color: var(--light-grey);
    width: calc(100% + 20px);
    height: 32px;
    border-radius: 20px;
    margin-left: -10px;
    position: absolute;
    z-index: -1;
    transition: top .4s ease;
}

header ul {
    width: 100%;
    margin-top: 70px;
}

header ul li {
    width: 100%;
    list-style: none;
    font-weight: 500;
    font-size: 18px;
    padding-block: 5px;
    margin-block: 15px;
    cursor: pointer;
    line-height: 22px;
}

header ul li.active {
    background-color: var(--light-grey);
    border-radius: 20px;
}

header ul hr {
    width: 60%;
    margin-left: 20%;
}