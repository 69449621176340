#login {
    width: 100vw;
    height: 100vh;
}

#login .form {
    width: 50vw;
    height: 100vh;
    float: left;
    display: flex;
    justify-content: center;
    align-items: center;
}

#login .content {
    width: 400px;
}

#login .content .text-field {
    
    margin-top: 70px;
}

#login h1 {
    font-size: 25px;
    text-align: center;
    margin-bottom: 70px;
}

#login .picture {
    width: 50vw;
    height: 100vh;
    float: left;
    background-size: cover;
    background-position: center;
    background-image: url("../assets/img/background.JPG");
}

#login .button {
    width: 100%;
    margin-top: 50px;
    transition: margin .2s, width .2s;
}

#login .button:hover {
    width: calc(100% - 5px);
    margin-inline: 2.5px;
}

#login .form .info {
    position: absolute;
    bottom: 40px;
    font-size: 16px;
}

#login .form .info a {
    font-weight: 600;
    cursor: pointer;
    text-decoration: none;
}

#login .form .info a:hover {
    text-decoration: underline;
}

#login .checkbox {
    margin-top: 65px;
}