.button.back-button {
    width: 100px;
}

.button.back-button svg {
    transition: margin .2s;
}

.button.back-button:hover svg {
    margin-left: -5px;
}

.button.button-forward svg {
    transform: rotate(180deg);
    height: 18px;
    transition: margin .3s;
}

.button.button-forward:not(.disabled):hover svg {
    margin-left: 13px;
    margin-right: -1px;
}