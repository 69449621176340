.page {
    background-size: cover;
    background-image: url("../assets/img/background-blur.JPG");
    height: 100vh;
    width: calc(99vw - 400px);
    float: right;
}

#dashboard.header-mobile .page {
    width: 99vw;
}

.page.small .main-box {
    --box-width: 650px;
}

.page .main-box {
    --box-height: 730px;
    --box-width: 1000px;
    background-color: white;
    width: var(--box-width);
    margin-inline: calc(50% - var(--box-width) / 2 - 50px);
    height: var(--box-height);
    margin-block: calc(50vh - var(--box-height) / 2 );
    border-radius: 10px;
    padding-inline: 50px;
}

.page .main-box .header {
    padding-top: 30px;
}

.page .header.search-header {
    height: 100px;
}

.page .header.search-header .text-field {
    width: 400px;
    float: left;
}

.page .header.search-header .button {
    float: right;
}

.page .header.search-header .icon-button {
    margin-left: 20px;
    float: left;
}

.page.simple-page h1 {
    margin-top: 60px;
    margin-bottom: 40px;
    float: left;
    font-size: 27px;
    font-weight: 600;
    width: 100%;
}

.page.simple-page p.info {
    font-size: 18px;
    color: var(--mid-grey);
}

.page.simple-page .button {
    margin-top: 80px;
}

.page.simple-page .multiple-select {
    margin-top: 30px;
}

.page .content-box {
    
    height: calc(var(--box-height) - 170px);
    overflow-y: auto;
}

.page.small.simple-page .text-field {
    margin-top: 40px;
    float: left;
    width: 60%;
    margin-inline: 20%;
}

.page.small.simple-page .text-field:nth-child(1) {
    margin-top: 100px;
}

.page.small.simple-page .button {
    width: 60%;
    margin-inline: 20%;
    margin-top: 100px;
    float: left;
}