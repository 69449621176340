.edit-img-row {
    width: 100%;
    display: flex;
    gap: 10px;
}

.edit-img-row .add-image {
    border: 2px solid var(--mid-grey);
    border-radius: 15px;
    height: 126px;
    margin-top: 16px;
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 10px;
    cursor: pointer;
}

.edit-img-row .add-image p {
    margin-block: 5px;
}

.edit-img-row .add-image svg {
    transition: transform .1s;
}

.edit-img-row .add-image:hover svg {
    transform: scale(0.9);
}

p.edit-image-row-title {
    font-size: 18px;
    font-weight: 500;
    margin-top: 25px;
    margin-bottom: 10px;
}