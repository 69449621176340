.link-event-page .event-list {
    margin-top: 20px;
    max-height: 300px;
    overflow-y: auto;
}

.link-event-page h2 {
    font-size: 22px;
    font-weight: 700;
}
.link-event-page .event-list p {
    font-size: 20px;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.link-event-page .event-list p.active {
    font-weight: 600;
}

.link-event-page .event-list p svg {
    width: 20px;
    margin-right: 15px;
}