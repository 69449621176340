#create-date .repeat {
    width: 100%;
    display: flex;
    gap: 50px;
}

#create-date .repeat .left {
    width: 200px;
}

#create-date .repeat .frequency {
    height: 100px;
    float: left;
    width: 100%;
}

#create-date .repeat .multiple-select .dropdown {
    width: 100%;
}

#create-date .repeat .frequency input {
    border: none;
    border-bottom: 3px solid var(--mid-grey);
    width: 40px;
    margin-right: 20px;
    outline: none;
    text-align: center;
    padding-block: 3px;
    font-size: 18px;
}

/* Chrome, Safari, Edge, Opera */
#create-date .repeat .frequency input::-webkit-outer-spin-button,
#create-date .repeat .frequency input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
#create-date .repeat .frequency input[type=number] {
  -moz-appearance: textfield;
}

#create-date .repeat p.info {
    margin-top: 20px;
}