.helper-list-box {
    height: 130px;
    background-color: var(--light-grey);
    padding: 10px;
    border-radius: 10px;
    cursor: pointer;
    padding-inline: 20px;
}

.helper-list-box p {
    font-size: 17px;
    line-height: 25px;
}

.helper-list-box p span {
    margin-left: 30px;
}

.helper-list-box p.location {
    opacity: .5;
}