#event-page .event-list a {
    font-weight: 600;
    font-size: 16px;
    margin-top: 15px;
    float: left;
    color: var(--mid-grey);
    cursor: pointer;
}

#event-page .filter {
    width: 100%;
    float: left;
}

h2.empty {
    text-align: center;
    margin-top: calc(var(--box-height) / 4);
    color: var(--mid-grey);
    font-size: 24px;
    font-weight: 600;
    width: 50%;
    margin-inline: 25%;
}