#helper .list {
    display: grid;
    grid-template-columns: calc(50% - 15px) calc(50% - 15px);
    gap: 30px;
}

#create-aider .linked {
    margin-top: 10px;
    font-size: 17px;
}

#create-aider .delete-icon {
    margin-top: 40px;
    float: left;
}

#create-aider .delete-icon p {
    margin-top: 7px;
}

#create-aider .dropdown {
    width: 400px;
}