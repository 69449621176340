.article-list-el {
    width: 100%;
    height: 180px;
}
.article-list-el .arrows {
    width: 50px;
    float: left;
    margin-left: 15px;  
}

.article-list-el .arrows .icon-button {
    transform: rotate(90deg);
    width: 30px;
    height: 20px;
    margin-top: 20px;
}

.article-list-el .arrows .icon-button:hover {
    margin-inline: 0;
}

.article-list-el .arrows .icon-button:hover svg {
    margin-left: -4px;
}

.article-list-el .arrows .icon-button:nth-child(2) {
    transform: rotate(-90deg);
    margin-top: calc(180px - 80px);
}

.article-list-el .arrows .icon-button svg {
    filter: invert(1);
    transition: margin .5s;
    width: 15px;
}

.article-list-el .paragraph-right {

    width: calc(100% - 70px);
    height: calc(180px);
    float: right;
    cursor: pointer; 
}

.article-list-el .paragraph-right:hover .hover {
    opacity: 1;
}

.article-list-el .paragraph-right .hover {
    width: 100%;
    height: calc(180px);
    margin-top: -180px;
    opacity: 0;
    transition: opacity .3s;
    display: flex;
}

.article-list-el .paragraph-right .hover .inner {
    height: calc(180px);
    background-color: rgba(0, 0, 0, .6);
    width: 100%;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;

}

.article-list-el .paragraph-right .hover a {
    color: white;
    font-size: 21px;
    margin-left: 10px;

}

.article-list-el .paragraph-right .hover svg {
    width: 30px;
}

.article-list-el .paragraph-content {
    display: grid;
    grid-template-columns: calc(33% - 6px) calc(34% - 6px) calc(33% - 6px);
    margin-right: 15px;
    width: calc(100% - 30px);
    height: calc(180px - 30px);
    gap: 9.5px;
    padding-block: 15px;
    padding-left: 15px;
}

.article-list-el .paragraph-content .image-box {
    height: calc(180px - 30px);
    width: 100%;
}

.article-list-el .paragraph-content.img-left {
    grid-template-areas: "b a a";
}

.article-list-el .paragraph-content.img-right {
    grid-template-areas: "a a b";
}

.article-list-el .paragraph-content.img-fs {
    grid-template-areas: "b b b";
}

.article-list-el .paragraph-content.text-only {
    grid-template-areas: "a a a";
}

.article-list-el .paragraph-content.text-center {
    grid-template-areas: "b a c";
}
.article-list-el .paragraph-content.text-center .image-box:nth-child(2) {
    grid-area: c;
}

.article-list-el .paragraph-content.three-img {
    grid-template-areas: "a b c";
}

.article-list-el .paragraph-content.three-img .image-box:nth-child(2) {
    grid-area: a
}

.article-list-el .paragraph-content.three-img .image-box:nth-child(4) {
    grid-area: c;
}

.article-list-el .paragraph-content .image-box {
    grid-area: b;
}

.article-list-el .paragraph-content p.text {
    grid-area: a;
    font-size: 15px;
    overflow: hidden;
}

.article-list-el .paragraph-content p p {
    line-height: 15px;
    min-height: 15px;   
}