.switch {
    background-color: var(--light-grey);
    border-radius: 20px;
    padding: 4px;
    display: inline-block;
    width: 200px;
}

.switch a {
    width: 90px;
    border-radius: 20px;
    padding: 4px;
    width: calc(50% - 10px);
    text-align: center;
    display: inline-block;
    cursor: pointer;
}

.switch a.active {
    background-color: var(--white);
}