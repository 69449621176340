#content .main-box .content {
    width: 80%;
    margin-inline: 10%;
}

#content .main-box .content h3 {
    margin-top: 20px;
    float: left;
    width: 100%;
    font-weight: 400;
    font-size: 20px;
}

#content .main-box .content .text-field {
    float: left;
    width: 100%;
    margin-top: 15px;
}

#content .main-box {
    overflow-y: auto;
}